import React, { useState, useContext } from "react";
import {
  Typography,
  Button,
  Container,
  Box,
  Input,
  Alert,
  Fade,
  Paper,
  FormLabel
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandsClapping, faThumbsUp
} from "@fortawesome/free-solid-svg-icons";

import './Endscreen.scss';

import ConfirmationDialog from "../dialogs/ConfirmationDialog";
import UrlContext from "../../urlContext";

const EndScreen = (props) => {
  const { url } = useContext(UrlContext);
  const { email, answers, huntId } = props;
  const [error, setError] = useState(false);
  const [confirmation, setConfirmation] = useState(false);

  const setEmail = (e) => {
    setError(false);
    props.setEmail(() => e.target.value);
  };

  const handleSubmit = () => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(email)) {
      fetch(url + "/submissions/", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          email: email,
          huntId: huntId,
          answers: answers
        })
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.success) {
            setConfirmation(true);
          } else {
            console.log('Something went wrong');
          }
        });
    } else {
      setError(true);
    }
  };

  return (

    <Container
      className={'end-screen'}
      disableGutters
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
            justifyContent: 'space-between'
      }}
    >
      <ConfirmationDialog open={confirmation} setOpen={setConfirmation} confirmFn={props.resetFunction} />
      <Paper>
        <Container
          sx={{ p: "1rem", bgcolor: "primary.secondary", color: "white" }}
        >
          <Typography variant="h5" sx={{}} align="left">
            <FontAwesomeIcon style={{ margin: 0 }} icon={faThumbsUp} />{" "}
            Proficiat!
          </Typography>
        </Container>
        <Container sx={{ p: "1rem" }}>
        <Typography
          variant="p"
          sx={{
            mb: 2
          }}
          align="center"
        >
           Je hebt de schattenjacht tot een goed einde gebracht! <br/><br/><FontAwesomeIcon style={{ margin: 0 }} icon={faHandsClapping} /> Tot de volgende keer!
        </Typography>
        </Container>
      </Paper>
      <Paper
        sx={{
          textAlign: 'center',
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          mt: 2,
          padding: '1rem'
        }}
      >
        <Typography
          variant="p"
          sx={{
            mb: 2,
          }}
          align="center"
        >
          Wil je deelnemen aan de prijsverloting, laat dan hier je e-mailadres achter.
        </Typography>
        <FormLabel htmlFor="emailfield" error={error} sx={{ textAlign: "left" }}>
          E-mail
        </FormLabel>
        <Input
          error={error}
          id="emailfield"
          aria-describedby="email"
          value={props.email}
          onChange={setEmail}
          sx={{ mb: 2 }}
        />
        {error && (
          <Fade in={error}>
            <Alert severity="error" sx={{ mb: 2 }}>
              Gelieve een <strong>correct e-mailadres</strong> in te voeren!
            </Alert>
          </Fade>
        )}
        <Button sx={{ mb: 2 }} variant="contained" onClick={handleSubmit}>
          Verstuur
        </Button>
      </Paper>

      <Box
        sx={{
            textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          mt: 2,
        }}
      >
        <Button variant="outlined"  onClick={props.resetFunction}>
          Terug naar start
        </Button>
      </Box>
    </Container>
  );
};

export default EndScreen;
