import React, { useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Polygon,
  Circle,
  useMap,
  useMapEvents,
} from "react-leaflet";
import { iconCircle } from "./icons/icon";
import "./LeafletMap.scss";

const LeafletMap = (props) => {
  const { markers, polygon, center, currentPosition, zoom, setZoom } = props;

  const renderCenter = () => {
    if (currentPosition) {
      return [currentPosition[0], currentPosition[1]];
    } else {
      return [0, 0];
    }
  };

  const renderPolygon = () => {
    if (polygon) {
      if (polygon.length > 2) {
        return <Polygon positions={polygon} pathOptions={{ color: "blue" }} />;
      } else {
        if (polygon.length === 1) {
          return (
            <Circle
              center={polygon[0]}
              radius={20}
              pathOptions={{ color: "blue" }}
            />
          );
        }
      }
    }
  };

  const ZoomTracker = () => {
    const map = useMapEvents({
      zoom() {
        setZoom(() => map.getZoom());
      },
    });
    return null;
  };

  return (
    <MapContainer
      center={renderCenter()}
      zoom={zoom}
      style={{ width: "100%", height: "100%" }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <ZoomTracker />
      {currentPosition && (
        <Marker
          position={[currentPosition[0], currentPosition[1]]}
          icon={iconCircle}
        ></Marker>
      )}
      {markers &&
        markers.map((marker, index) => (
          <Marker key={index} position={[marker.lat, marker.lng]}></Marker>
        ))}
      {polygon && renderPolygon()}
    </MapContainer>
  );
};

//

export default LeafletMap;
