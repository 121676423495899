import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const CloseIcon = (props) => {
    return (
            <div className="icon close-icon">
                <FontAwesomeIcon icon={faTimes} size="lg" onClick={(e)=>{props.backFn();}} />
            </div>
    );
};

export default CloseIcon;
