import React from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import './Loader.scss'

const Loader = () => {
  return (
    <FontAwesomeIcon className="spinner" style={{margin: 0, padding: '0 0.5rem 0 0'}} icon={faSpinner} />
  )
}

export default Loader;
