import React, { useState, useEffect, useContext } from "react";

import ProgressIndicator from "./ProgressIndicator";
import {
  Typography,
  Button,
  Container,
  Box,
  Input,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Paper,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb, faForward } from "@fortawesome/free-solid-svg-icons";
import UrlContext from "../urlContext";

import { checkValidLocation } from "../helpers.js";

const Question = (props) => {
  const {
    setOpenModal,
    setLocationDialogText,
    setOpenWarningModal,
    setWarningFunction,
    questionIndex,
    currentValue,
    showMap,
  } = props;
  const { title, type, options, id } = props.question;

  const [value, setValue] = useState("");
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState(" ");
  const [isActive, setIsActive] = useState(false);
  const [tries, setTries] = useState(0);
  const { development } = useContext(UrlContext);

  useEffect(() => {
    setIsActive(questionIndex === currentValue);
  }, [questionIndex, currentValue]);

  const handleChange = (e) => {
    setError(false);
    setHelperText(" ");
    setValue(e.target.value);
  };

  const showHint = (e) => {
    navigator.geolocation.getCurrentPosition(
      (geolocation) => {
        const polygon = props.question.location.map((locationPoint) => [
          locationPoint.latitude,
          locationPoint.longitude,
        ]);
        if (geolocation) {
          showMap(
            [geolocation.coords.latitude, geolocation.coords.longitude],
            polygon
          );
        } else {
          showMap(polygon[0], polygon);
        }
      },
      (GeolocationPositionError) => {
        console.log("GeolocationPositionError");
      },
      {
        enableHighAccuracy: true,
      }
    );
  };

  const handleAnswer = (skip) => {
    //Validate if something is sellected
    if (type === "picture") {
      if (skip === true) {
        setTries(0);
        props.nextQuestionFn();
      } else {
        checkValidLocation(
          props.question.location.map((locationPoint) => [
            locationPoint.latitude,
            locationPoint.longitude,
          ]),
          () => {
            props.nextQuestionFn();
          },
          () => {
            setTries((prev) => ++prev);

            if (tries > 2) {
              setLocationDialogText(
                "Volgens de GPS is je locatie niet correct, u probeerde al " +
                  tries +
                  ' keer. U kan nu de "Ik ben zeker dat ik er ben" knop gebruiken.'
              );
            } else {
              setLocationDialogText(
                "Uw locatie is niet correct, kijk nog eens rond en probeer opnieuw!"
              );
            }

            setOpenModal(true);
          },
          development
        );
      }
    } else {
      if (value !== null && value !== "") {
        props.setAnswers((prevAnswers) => {
          let newAnswersArray = prevAnswers.filter(
            (answer) => answer.objectiveId !== id
          );
          newAnswersArray.push({ objectiveId: id, value: value });
          return newAnswersArray;
        });
        props.nextQuestionFn();
      } else {
        setHelperText("Gelieve een optie te kiezen");
        setError(true);
      }
    }
  };

  const renderActive = () => {
    if (isActive) {
      return (
        <Paper sx={{ padding: "1rem" }}>
          <Typography
            variant="h5"
            sx={{
              mb: 2,
            }}
            align="center"
          >
            {(type === "input" || type === "multi") && title}
            {type === "picture" && "Zoek de volgende locatie:"}
          </Typography>
          <Typography
            variant="h5"
            sx={{
              mb: 2,
            }}
            align="center"
          >
            {type === "picture" && title}
          </Typography>
          {type === "picture" && (
            <img
              src={props.question.imageUrl}
              style={{ width: "100%", height: "auto" }}
              alt={"location"}
            />
          )}
          {type === "input" && (
            <FormControl sx={{ mb: 2, flex: 1, width: "100%" }}>
              <FormLabel
                htmlFor="my-input"
                error={error}
                sx={{ textAlign: "left", display: "block" }}
              >
                Antwoord
              </FormLabel>
              <Input
                error={error}
                id="my-input"
                aria-describedby="my-helper-text"
                style={{ marginTop: 0 }}
                value={value}
                onChange={handleChange}
              />
            </FormControl>
          )}
          {type === "multi" && (
            <FormControl error={error} variant="standard">
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={handleChange}
              >
                {options &&
                  options.map((option, index) => (
                    <FormControlLabel
                      key={index}
                      value={index}
                      control={<Radio />}
                      label={option}
                    />
                  ))}
              </RadioGroup>
              <FormHelperText>{helperText}</FormHelperText>
            </FormControl>
          )}
          <Box textAlign="center" sx={{ mt: 2, display: "flex" }}>
            <Button variant="contained" sx={{ flex: 1 }} onClick={handleAnswer}>
              {(type === "input" || type === "multi") && "Bevestig!"}
              {type === "picture" && "Gevonden!"}
            </Button>
            {type === "picture" && (
              <Button
                variant="outlined"
                sx={{ ml: 2, padding: "5px" }}
                onClick={showHint}
                startIcon={
                  <FontAwesomeIcon
                    style={{ margin: 0, padding: "0 0.5rem 0 0" }}
                    icon={faLightbulb}
                  />
                }
              >
                Hint
              </Button>
            )}
          </Box>
          {type === "picture" && tries > 0 && (
            <Button
              variant="contained"
              disabled={tries < 3}
              sx={{
                flex: 1,
                justifyContent: "flex-start",
                mt: 2,
                width: "100%",
                backgroundColor: tries > 2 && "#E20077",
              }}
              onClick={() => {
                setWarningFunction(() => () => {
                  setOpenWarningModal(false);
                  handleAnswer(true)
                })
                setOpenWarningModal(true);
              }}
            >
              <ProgressIndicator
                style={{ width: 50, minHeight: 62, marginRight: "1rem" }}
                value={tries}
                completeValue={3}
                completeText={"verder"}
                completeIcon={
                  <FontAwesomeIcon
                    style={{ margin: 0, padding: "0 0.5rem 0 0" }}
                    icon={faForward}
                  />
                }
              />
              Ik ben zeker dat ik er ben!
            </Button>
          )}
        </Paper>
      );
    } else {
      return "";
    }
  };

  return (
    <Container
      disableGutters
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
      }}
    >
      {renderActive()}
    </Container>
  );
};

export default Question;
