import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapLocationDot,
  faLightbulb,
  faTriangleExclamation,
  faRefresh,
} from "@fortawesome/free-solid-svg-icons";

import WarningDialog from '../dialogs/WarningDialog';

import {
  Typography,
  Button,
  Paper,
  Link,
  Container,
  Box,
  ListItem,
} from "@mui/material";

const Tips = (props) => {
    
  const {couponUrl} = props;
   
  return (
    <Paper>
      <Container
        sx={{ p: "1rem", bgcolor: "primary.secondary", color: "white" }}
      >
        <Typography variant="h5" sx={{}} align="left">
          <FontAwesomeIcon style={{ margin: 0 }} icon={faLightbulb} />{" "}
          Praktische Tips
        </Typography>
      </Container>
      <Container sx={{ p: "1rem" }}>
         {couponUrl &&  <ListItem sx={{ display: "list-item", p: "0" }}>
          <Link
            sx={{ color: "primary.secondary", fontWeight: "bold" }}
            underline="none"
            href={couponUrl}
            target="_blank"
            rel="noopener"
          >
            Kortingsbonnen vind je hier!
          </Link>
        </ListItem>
        }
        <ListItem sx={{ display: "list-item", p: "0" }}>
          wil je altijd op de hoogte zijn van kermissen en markten in jouw stad?
          <br />
          Ga dan naar{" "}
          <Link
            href="https://foriapp.be"
            sx={{ color: "primary.secondary", fontWeight: "bold" }}
            target="_blank"
            rel="noopener"
            underline="none"
          >
            foriapp.be
          </Link>
        </ListItem>
      </Container>
    </Paper>
  );
};

const StartScreen = (props) => {
  const [navigatorAvailable, setNavigatorAvailable] = useState(false);
  const [warningOpen, setWarningOpen] = useState(false);
  const {couponUrl} = props;
  
  const geoLocationCheck = (callback, invalidCallback) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        () => {
          setNavigatorAvailable(true);
          if (callback && typeof callback === "function") {
            callback();
          }
        },
        () => {
          setNavigatorAvailable(false);
          if (invalidCallback && typeof invalidCallback === "function") {
            invalidCallback();
          }
        }
      );
    } else {
      setNavigatorAvailable(false);
    }
  };

  const ValidStart = () => {
    return (
      <Paper sx={{ p: "1rem" }}>
        <Typography
          variant="h5"
          sx={{
            mb: 2,
          }}
          align="center"
        >
          Ga naar {props.title} om de zoektocht te beginnen!
        </Typography>
        <Box
          textAlign="center"
          sx={{
            display: "flex",
            mt: 2,
          }}
        >
          <Button
            sx={{ flex: 1 }}
            variant="contained"
            onClick={() => {
              geoLocationCheck(() => {
                props.validLocationFn();
              });
            }}
          >
            Starten!
          </Button>
        </Box>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-end",
          }}
        >
          <Button
            startIcon={
              <FontAwesomeIcon
                style={{ margin: 0, padding: "0.25rem 0.5rem" }}
                icon={faMapLocationDot}
              />
            }
            variant="outlined"
            onClick={() => {
              geoLocationCheck(() => {
                props.showMap();
              });
            }}
          >
            Locatie hint!
          </Button>
        </Box>
      </Paper>
    );
  };

  const EnableGeolocationWarning = () => {
    return (
      <Paper>
      <WarningDialog open={warningOpen} setOpen={setWarningOpen} dialogText={'De browser beschikt niet over de rechten om geolocatie te gebruiken.'} dialogTitle={'Geolocatie'} />
        <Container sx={{ p: "1rem", bgcolor: "#cb4747", color: "white" }}>
          <Typography variant="h5" sx={{}} align="left">
            <FontAwesomeIcon
              style={{ margin: 0 }}
              icon={faTriangleExclamation}
            />{" "}
            Opgelet!
          </Typography>
        </Container>
        <Paper>
          <Container sx={{ p: "1rem" }}>
            <Typography variant="p" sx={{mb:2, display: 'inline-block'}}>
              De locatievoorzieningen voor uw browser moeten geactiveerd zijn om
              deze zoektocht te starten.
            </Typography>
            <Typography variant="p" sx={{mb:2, display: 'inline-block'}}>
              Eens u deze voorzieningen voor uw browser hebt geactiveerd, klik dan op de
              "Opnieuw Proberen" knop!
            </Typography>
            <Button
              sx={{width: '100%'}}
              startIcon={
                <FontAwesomeIcon
                  style={{ margin: 0, padding: "0.25rem 0.5rem" }}
                  icon={faRefresh}
                />
              }
              variant="contained"
              onClick={() => {
                geoLocationCheck(null, () => {
                  setWarningOpen(true);
                })
              }}
            >
              Opnieuw Proberen
            </Button>
          </Container>
        </Paper>
      </Paper>
    );
  };

  useEffect(() => {
    geoLocationCheck();
  }, []);

  return (
    <Container
      sx={{
        padding: "0",
        gap: 2,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
      }}
    >
      {navigatorAvailable ? <ValidStart /> : <EnableGeolocationWarning />}

      <Tips couponUrl={couponUrl}/>
    </Container>
  );
};

export default StartScreen;
