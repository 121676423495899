import React, {useContext} from "react";
import { useNavigate, Link, Route, Routes } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UrlContext from "../urlContext";

import "./SettingsView.scss";
import LegalView from "./LegalView";
import CloseIcon from "../components/CloseIcon";

const SettingsItem = (props) => {
  return (
    <li>
      <Link to={`/settings/${props.route}`}>{props.titel}</Link>
    </li>
  );
};

const SettingsMenu = (props) => {
  return (
    <ul className="settings-list">
      <SettingsItem titel="wettelijk" route="wettelijk" />
    </ul>
  );
};

const SettingsView = (props) => {
  const {version} = useContext(UrlContext)
  const navigate = useNavigate();

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <>
            <div className="header-bar">
              <div className="flex-filler" style={{ width: "12.5px" }}></div>
              <SettingsMenu />
              <CloseIcon
                backFn={() => {
                  navigate("/");
                }}
              />
            </div>
            <div className="app-version">{version}</div>
          </>
        }
      ></Route>
      <Route path="/wettelijk" element={<LegalView />}></Route>
    </Routes>
  );
};

export default SettingsView;
