const classifyPoint = require("robust-point-in-polygon");

const checkPolygonLocation = (geolocation, polygon) => {
  if (polygon) {
    const result = classifyPoint(polygon, [
      geolocation.latitude,
      geolocation.longitude,
    ]);

    // -1 / 0 -> Inside or on bounds
    // 1 -> Outside bounds

    if (result === -1 || result === 0) {
      return true;
    } else {
      return false;
    }
  }
};

const checkCircleLocation = (geolocation, locationCoord, radius) => {
  // geolocation: object with latitude & longitude
  // locationCoord: object with latitude & longitutde
  // radius : distance in meter
  const R_CONV = radius / 111111; // conversie naar coordinatenafstand
  let comparison = Math.sqrt(Math.pow(geolocation.latitude - locationCoord.latitude, 2) + Math.pow(geolocation.longitude - locationCoord.longitude, 2));
  return comparison <= R_CONV;
};

export const checkValidLocation = (
  coords,
  validCallback,
  invalidCallback,
  development
) => {
  const RADIUS = 40; // in meter

  if (development) {
    return validCallback();
  }

  navigator.geolocation.getCurrentPosition((geolocation) => {
    if (geolocation) {
      let locationIsValid = false;
      if (coords && coords !== null) {
        if (Array.isArray(coords)) {
          if (coords.length > 1) {
            locationIsValid = checkPolygonLocation(geolocation.coords, coords);
          } else {
            locationIsValid = checkCircleLocation(
              geolocation.coords, {
                latitude: coords[0][0],
                longitude: coords[0][1]
              },
              RADIUS
            );
          }
        } else if (
          typeof coords === "object" &&
          coords.longitude &&
          coords.latitude
        ) {
          locationIsValid = checkCircleLocation(
            geolocation.coords,
            coords,
            RADIUS
          );
        }
      }

      if (!locationIsValid) {
        invalidCallback();
      } else {
        validCallback();
      }
    }
  }, (GeolocationPositionError) => {
    console.log('GeolocationPositionError');
  }, {
    enableHighAccuracy: true
  });
};
