import React, { useState, useEffect, useContext } from "react";

import { checkValidLocation } from "../../helpers.js";

import StartScreen from "./StartScreen";
import EndScreen from "./EndScreen";
import NotFoundScreen from "./NotFoundScreen";

import TabPanel from "../TabPanel";
import Question from "../Question";
import ProgressBar from "../ProgressBar";
import IncorrectLocationDialog from "../dialogs/IncorrectLocationDialog.js";
import CancelDialog from "../dialogs/CancelDialog.js";
import MapDialog from "../dialogs/MapDialog";
import BackButton from "../BackButton";
import {
  Typography
} from "@mui/material";

import Loader from "../Loader";

import UrlContext from "../../urlContext";
import { CSSTransition } from "react-transition-group";

const Hunt = (props) => {
  const { url, identifier } = useContext(UrlContext);
  const [data, setData] = useState(null);
  const [email, setEmail] = useState("");
  const [validLocation, setValidLocation] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [totalSteps, setTotalSteps] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [openMapModal, setOpenMapModal] = useState(false);
  const [title, setTitle] = useState("");
  const [startlocation, setStartlocation] = useState("");
  const { development } = useContext(UrlContext);
  const [found, setFound] = useState(false);
  const [locationDialogText, setLocationDialogText] = useState('');
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [warningFunction, setWarningFunction] = useState();
  const [couponUrl, setCouponUrl] = useState(null);

  const { selectedHuntId, setAnimateHunt, answers, setAnswers } = props;

  const resetToDefaults = () => {
    resetLocalStorage();
    setAnswers([]);
    setCompleted(false);
    setValidLocation(false);
    setEmail("");
    setActiveStep(0);
    props.resetFn();
  };

  const resetLocalStorage = () => {
    let progress = JSON.parse(localStorage.getItem("progress"));
    if (progress !== null && progress[selectedHuntId]) {
      delete progress[selectedHuntId];
      if (Object.entries(progress).length === 0) {
        localStorage.removeItem("progress");
      } else {
        localStorage.setItem("progress", JSON.stringify(progress));
      }
    }
  };

  const loadHunt = (callback) => {
    fetch(
      url +
        "/hunts/" +
        selectedHuntId +
        "?" +
        new URLSearchParams({
          opener: identifier,
        }),
      {
        method: "GET",
        headers: new Headers({ accept: "application/json" }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setFound(true);
          setData(response.data);
          callback();
        } else {
          if (response.success === false) {
            setFound(false);
            if (callback && typeof callback === "function") {
              callback();
            }
          }
        }
      });
  };

  useEffect(() => {
    let progress = localStorage.getItem("progress");
    if (selectedHuntId !== "") {
      if (progress !== null) {
        progress = JSON.parse(progress);
        if (progress[selectedHuntId]) {
          if (progress[selectedHuntId].activeStep > 0) {
            setValidLocation(true);
            setAnswers(progress[selectedHuntId].answers);
            setActiveStep(progress[selectedHuntId].activeStep);
            setCompleted(progress[selectedHuntId].completed);
          }
        }
      }
    } else {
    }
  }, [selectedHuntId]);

  const setProgressInLocalStorage = () => {

    let progress =  JSON.parse(localStorage.getItem("progress"));

    if (progress === null) {
      progress = {};
    }

    if (selectedHuntId !== "") {
      progress[selectedHuntId] = {
        completed: completed,
        answers: answers,
        activeStep: activeStep,
      };

      localStorage.setItem("progress", JSON.stringify(progress));

      if (activeStep > 0) {
        setValidLocation(true);
      }
    }
  };

  useEffect(() => {
    setProgressInLocalStorage();
  }, [activeStep]);

  useEffect(() => {
    setProgressInLocalStorage();
  }, [completed]);

  useEffect(() => {
    loadHunt(() => {
      setAnimateHunt(true);
    });
    //Unmount
    return () => {
      setAnimateHunt(false);
    };
  }, []);

  useEffect(() => {}, [data, totalSteps]);

  useEffect(() => {
    if (data && data.objectives) {
      setTitle(data.title);
      setStartlocation(data.startlocation);
      setTotalSteps(data.objectives.length);
      
      if (data.couponUrl) {
          setCouponUrl(data.couponUrl);
      }
    }
  }, [data]);

  const [markers, setMarkers] = useState(false);
  const [polygon, setPolygon] = useState(false);
  const [currentPosition, setCurrentPosition] = useState(false);

  const resetMap = () => {
    setCurrentPosition(false);
    setMarkers(false);
    setPolygon(false);
  };

  const showMap = (currentPosition, polygon, markers) => {
    resetMap();

    if (currentPosition) {
      setCurrentPosition(currentPosition);
    }

    if (polygon) {
      setPolygon(polygon);
    }

    if (markers) {
      setMarkers(markers);
    }

    setOpenMapModal(true);
  };

  const render404 = () => {
    return (
      <div className="hunt">
        <NotFoundScreen reloadFn={resetToDefaults} />
      </div>
    );
  };

  const renderHunt = () => {
    return (
      <div className="hunt">
        <MapDialog
          open={openMapModal}
          setOpen={setOpenMapModal}
          markers={markers}
          polygon={polygon}
          currentPosition={currentPosition}
        />
        <IncorrectLocationDialog open={openModal} setOpen={setOpenModal} dialogText={locationDialogText}/>
        <CancelDialog
          open={openWarningModal}
          setOpen={setOpenWarningModal}
          confirmFn={warningFunction}
          text={'Volgens de gps ben je er niet helemaal, wil je toch doorgaan?'}
          title={'Overslaan'}
        />
        {!validLocation ? (
          <>
            <BackButton
              onClick={resetToDefaults}
              style={{ position: "absolute", top: "0", left: "0" }}
            ></BackButton>
            <Typography
              variant="h5"
              sx={{
                mb: 2,
                ml: 4,
                mr: 4
              }}
              align="center"
            >{title}</Typography>
            <StartScreen
              title={startlocation}
              couponUrl={couponUrl}
              validLocationFn={() => {
                checkValidLocation(
                  data.location.map((locationPoint) => [
                    locationPoint.latitude,
                    locationPoint.longitude,
                  ]),
                  () => {
                    setValidLocation(true);
                  },
                  () => {
                    setValidLocation(false);
                    setLocationDialogText('Uw locatie is niet correct, kijk nog eens rond en probeer opnieuw!');
                    setOpenModal(true);
                  },
                  development
                );
              }}
              showMap={() => {
                navigator.geolocation.getCurrentPosition((geolocation) => {
                  const polygon = data.location.map((locationPoint) => [
                    locationPoint.latitude,
                    locationPoint.longitude,
                  ]);
                  if (geolocation) {
                    showMap(
                      [
                        geolocation.coords.latitude,
                        geolocation.coords.longitude,
                      ],
                      polygon
                    );
                  } else {
                    showMap(polygon[0], polygon);
                  }
                }, (GeolocationPositionError ) => {
                  console.log('GeolocationPositionError');
                }, {
                  enableHighAccuracy: true
                });
              }}
            />
          </>
        ) : !completed ? (
          <>
            {data && data.objectives && totalSteps ? (
              <>
                <ProgressBar activeStep={activeStep} totalSteps={totalSteps} />
                <TabPanel style={{ width: "100%", flex: 1 }} start={activeStep}>
                  {(wrapTab, value, nextTab) => {
                    return data.objectives.map((question, index, arr) => {
                      return wrapTab(index, value, arr, () => {
                        const handleNextTab = () => {
                          if (index === arr.length - 1) {
                            setCompleted(true);
                          } else {
                            setActiveStep((prev) => prev + 1);
                            nextTab();
                          }
                        };

                        return (
                          <Question
                            currentValue={value}
                            questionIndex={index}
                            setOpenModal={setOpenModal}
                            setOpenWarningModal={setOpenWarningModal}
                            setWarningFunction={setWarningFunction}
                            setLocationDialogText={setLocationDialogText}
                            question={question}
                            setAnswers={setAnswers}
                            nextQuestionFn={handleNextTab}
                            showMap={showMap}
                          />
                        );
                      });
                    });
                  }}
                </TabPanel>
              </>
            ) : (
              <Loader />
            )}
          </>
        ) : (
          <>
            {data ? (
              <CSSTransition in={true} appear={true} timeout={0}>
                <EndScreen
                  email={email}
                  answers={answers}
                  huntId={data.id}
                  setEmail={setEmail}
                  resetFunction={resetToDefaults}
                />
              </CSSTransition>
            ) : (
              <Loader />
            )}
          </>
        )}
      </div>
    );
  };

  if (typeof data !== "undefined" && found) {
    return renderHunt();
  } else {
    return render404();
  }
};

export default Hunt;
