import React, { useState, useEffect, useContext } from "react";
import { Routes, Route, Redirect } from "react-router-dom";
import "./App.scss";

import UrlContext from "./urlContext";
import HomeView from "./views/HomeView";
import HuntView from "./views/HuntView";
import SettingsView from "./views/SettingsView";
import Cookies from "./components/Cookies";

const App = () => {
  const [seenIntro, setSeenIntro] = useState(window.localStorage.getItem('seenIntro'));
  const { url, isLoading } = useContext(UrlContext);
  let introSeen = false;
  const [data, setData] = useState();
  const [dataIsSet, setDataIsSet] = useState(false);

  useEffect(() => {
    console.log('seenIntro', seenIntro);
        if (!seenIntro) {
            window.localStorage.removeItem('introSeen');
        }
    }, [seenIntro]);

  useEffect(() => {
    if (!isLoading && !dataIsSet) {
      fetch(url + "/hunts", {
        method: "GET",
        headers: new Headers({ accept: "application/json" })
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.success) {
            setData(response.data);
            setDataIsSet(true);
          }
        });
    }
  }, [isLoading]);

  const renderApp = () => {
    if (seenIntro) {
      return (
        <div className="App">
          <Routes>
            <Route exact path="/" element={<HomeView data={data} />} />
            <Route exact path="/settings/*" element={<SettingsView />} />
            <Route path="/:huntId" element={<HuntView data={data} />} />
          </Routes>
        </div>
      )
    } else {
      return (
        <div className="App"><Cookies setSeenIntro={setSeenIntro} /></div>
      )
    }
  }

  return (
    renderApp()
  )
};

export default App;
