import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Typography } from "@mui/material";
import HuntList from "../components/hunt/HuntList";
import Hunt from "../components/hunt/Hunt";
import { CSSTransition } from "react-transition-group";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

import "./HuntView.scss";

const HuntView = (props) => {
  const [answers, setAnswers] = useState([]);
  const [selectedHuntId, setSelectedHuntId] = useState(null);
  const [animateHunt, setAnimateHunt] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  const { huntId } = useParams();
  const navigate = useNavigate();

  const resetHunt = () => {
    setActiveIndex(null);
    clearLocalStorage(huntId);
    setSelectedHuntId("");
  };

  useEffect(() => {
    if (typeof huntId !== "undefined") {
      setSelectedHuntId(huntId);
    }
  }, []);

  useEffect(() => {
    if (selectedHuntId === "") {
      navigate("/");
    }
  }, [selectedHuntId]);

  const clearLocalStorage = (huntId) => {
    let localAnswers = JSON.parse(window.localStorage.getItem("answers"));
    if (localAnswers) {
      delete localAnswers[huntId];
      if (Object.entries(localAnswers).length === 0) {
        window.localStorage.removeItem("answers");
      } else {
        window.localStorage.setItem("answers", JSON.stringify(localAnswers));
      }
    }
  };

  const setAnswersLocalStorage = () => {
    if (selectedHuntId !== null && activeIndex !== null) {
      let answersObject = {};
      answersObject[selectedHuntId] = {
        answers: answers,
        currentStep: activeIndex,
      };
      window.localStorage.setItem("answers", JSON.stringify(answersObject));
    }
  };

  useEffect(() => {
    //Every time a step is clicked - active index is increased
    if (activeIndex !== null) {
      setAnswersLocalStorage();
    }
  }, [activeIndex]);

  return (
    <div className="hunt-view">
      {!selectedHuntId ? (
        <>
        <Typography variant="h5" sx={{ mb: 0}} align="left">
          Zoektochten
        </Typography>

        {props.data && props.data.length > 0 && (
          <>
          <Typography variant="p" sx={{ mb: 2 }} align="left">
             Selecteer de zoektocht die je wil starten!
           </Typography>
           <HuntList data={props.data} setSelectedHuntId={setSelectedHuntId} />
           </>
        )}
        {props.data && props.data.length === 0 && (
          <Typography variant="p" sx={{ mb: 2 }} align="left">
             Binnenkort vindt u hier nieuwe zoektochten!
           </Typography>
        )}
        </>
      ) : (
        <CSSTransition in={animateHunt} timeout={0}>
          <Hunt
            selectedHuntId={selectedHuntId}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            setAnimateHunt={setAnimateHunt}
            resetFn={resetHunt}
            answers={answers}
            setAnswers={setAnswers}
          />
        </CSSTransition>
      )}
    </div>
  );
};

export default HuntView;
