import React, {useState, useEffect, createContext} from 'react';
import {useSearchParams} from "react-router-dom";
import uuid from 'react-uuid';
const UrlContext = createContext();


export const UrlContextProvider = (props) => {
  //MAJOR MINOR PATCH
  const [version, setVersion] = useState('1.1.5');
  const [local, setLocal] = useState(false);
  const [development, setDevelopment] = useState(false);
  const [url, setUrl] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [identifier, setIdentifier] = useState(null);

  useEffect(() => {
      //load in data from postcodes
      if (!window.localStorage.getItem('identifier')) {
          window.localStorage.setItem('identifier',uuid());
      }
      setIdentifier(window.localStorage.getItem('identifier'));
  }, []);
  //check if uuid is Available

  useEffect(()=>{
    if (searchParams.get('development')) {
        setDevelopment(true);
    }
  },[])

  useEffect(()=>{
    if (local) {
      setUrl('http://localhost:8081/api');
      //setUrl('https://crito.be/api');
    } else {
      setUrl('/api');
    }
    setIsLoading(false);
  },[development]);

  return (
    <UrlContext.Provider value={{url: url, isLoading: isLoading, development: development, identifier: identifier, version: version}}>
      {props.children}
    </UrlContext.Provider>
  );
};

export default UrlContext
