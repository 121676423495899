import React, { useState } from "react";
import CancelDialog from "./dialogs/CancelDialog";
import { Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";


const BackButton = (props) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <CancelDialog
        open={openModal}
        setOpen={setOpenModal}
        confirmFn={props.onClick}
        text={'Ben je zeker dat je de zoektocht wil beïndigen?'}
        title={'Annuleren'}
      />
      <Button
        sx={{position: 'absolute', padding:0, margin:0, minWidth: 'auto'}}
        onClick={() => {
          props.onClick();
        }}
        variant={"outlined"}
        startIcon={<FontAwesomeIcon style={{margin: 0, padding: '0.25rem 0.5rem'}} icon={faArrowLeft} />}
      ></Button>
    </>
  );
};

export default BackButton;
