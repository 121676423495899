import React from "react";
import logo from "./critologo.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom";
import "./Header.scss";

const Header = (props) => {
  const { showHeader } = props;
  const navigate = useNavigate();

  if (showHeader) {
    return (
      <header className="header">
        <img src={logo} alt="Crito logo" />
        <div className="icon settings-icon">
          <FontAwesomeIcon
            icon={faBars}
            color="white"
            size="lg"
            onClick={() => {
                navigate("/settings/wettelijk");
                //navigate("/settings/");
            }
            }
          />
        </div>
      </header>
    );
  } else {
    return (
      <></>
    )
  }
};

export default Header;
