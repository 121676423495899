import React, {useState, useEffect} from "react";
import { Typography, Container, Paper } from "@mui/material";
import HuntView from "./HuntView";
import Header from "../components/Header";
import './HomeView.scss';


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";

const About = () => {
  return (
    <Container sx={{pt: 2, pb: 2, backgroundColor: '#1976d2'}}>
    <Typography variant="p" sx={{ mb: 2, color: 'white' }} align="left">
      CRI-TO is een digitale schattenjacht ideaal voor steden en gemeenten die hun burgers op een speelse manier tot bewegen willen aanzetten.
    </Typography>
    </Container>
  )
};

const LocationWarning = () => {
  return (
    <Paper>
      <Container
        sx={{ p: "1rem", bgcolor: "#cb4747", color: "white" }}
      >
        <Typography variant="h5" sx={{}} align="left">
          <FontAwesomeIcon style={{ margin: 0 }} icon={faTriangleExclamation} />{" "}
          Opgelet!
        </Typography>
      </Container>
      <Container sx={{ p: "1rem" }}>
      <Typography
        variant="p"
        sx={{
          mb: 2
        }}
        align="center"
      >
         De locatievoorzieningen voor uw browser moeten geactiveerd zijn om gebruik te maken van deze applicatie.
      </Typography>
      </Container>
    </Paper>
  )
};

const HomeView = (props) => {
  const [showHeader, setShowHeader] = useState(true)

  useEffect(() => {
    setShowHeader(true);
  },[])

  return (
    <div className="home">
      <Header showHeader={showHeader}/>
      <About />
      <LocationWarning />
      <HuntView data={props.data} showHeader={setShowHeader} />

    </div>
  );
};

export default HomeView;
