import React, { useEffect, useState, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import useWindowSize from "../../hooks/useWindowSize";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Button,
} from "@mui/material";
import "./HuntList.scss";
import UrlContext from "../../urlContext";
import { CSSTransition } from "react-transition-group";

const HuntListItem = (props) => {
  const { title, imageUrl } = props.data;
  const [height, setHeight] = useState(0);
  const imageEl = useRef(null);
  const { url } = useContext(UrlContext);
  const [imageLoaded, setImageLoaded] = useState(false);

  const loadImage = () => {
    const bgImage = new Image();
    bgImage.onload = function () {
      setImageLoaded(true);
      if(imageEl.current) {
          imageEl.current.style.backgroundImage = "url(" + bgImage.src + ")";
      }
    };
    bgImage.src = imageUrl;
  };

  useEffect(() => {
    loadImage();
  }, []);

  return (
    <CSSTransition in={imageLoaded} timeout={0}>
      <Link to={`/${props.data.id}`} className="hunt-list-item">
        <Card
          onClick={() => {
            props.clickfn(() => {
              return props.data.id;
            });
          }}
          sx={{ display: "flex", minHeight: "150px", mb: 2 }}
          elevation={2}
        >
          <div className="hunt-list-item-image" ref={imageEl}></div>
          <CardContent
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h5"
            >
              {title.charAt(0).toUpperCase() + title.slice(1)}
            </Typography>
            {/*<Button variant="contained">selecteren</Button>*/}
          </CardContent>
        </Card>
      </Link>
    </CSSTransition>
  );
};

const HuntList = (props) => {
  return (
    <Grid
      className="hunt-list"
      container
      sx={{ display: "flex", flexDirection: "column", height: "100%", flexWrap: "nowrap" }}
    >
      {typeof props.data !== "undefined" &&
        props.data.map((item) => (
          <HuntListItem
            key={item.id}
            data={item}
            clickfn={props.setSelectedHuntId}
          />
        ))}
    </Grid>
  );
};

export default HuntList;
