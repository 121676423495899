import React, {useState, useEffect} from 'react';
import "./ProgressBar.scss";

const ProgressBar = (props) => {
  const {activeStep, totalSteps} = props;
  const [width, setWidth] = useState('0%');

  useEffect(() => {
    setWidth((activeStep / totalSteps * 100) + '%');
  }, [activeStep]);

  return (
    <div className="progressbar">
      <div className="progress" style={{width: width}}></div>
    </div>
  )
}

export default ProgressBar;
