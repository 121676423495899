import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import {
  Typography,
  Button,
  Paper,
  Container,
  Box
} from "@mui/material";

const NotFoundScreen = (props) => {
  return (
    <Container
      sx={{
        padding: '0',
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <Paper sx={{p: '1rem'}}>
      <Typography
        variant="h5"
        sx={{
          mb: 2,
        }}
        align="center"
      >
        404
      </Typography>
      <Typography
        variant="p"
        sx={{
          mb: 2,
        }}
        align="center"
      >
        Zoektocht niet gevonden ...
      </Typography>
      <Box
        textAlign="center"
        sx={{
          display: 'flex',
          mt: 2,
        }}
      >
        <Button
          startIcon={<FontAwesomeIcon style={{margin: 0, padding: '0.25rem 0.5rem'}} icon={faArrowLeft} />}
          sx={{flex: 1}}
          variant="contained"
          onClick={() => {
            props.reloadFn()
          }}
        >
          Zoeken in de lijst
        </Button>
      </Box>
      </Paper>

    </Container>
  );
};

export default NotFoundScreen;
