import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import "./Modal.scss";

const Modal = (props) => {
  const mapdialog = useRef(null);
  const { open, setOpen, title, text, type, map } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderButtons = () => {
    switch (type) {
      case "map":
        return <Button onClick={handleClose}>VERDER ZOEKEN</Button>;
        break;
      case "OK":
        return (
          <>
            <Button onClick={props.confirmFn}>OK, TERUG NAAR START</Button>
          </>
        );
      case "confirm":
        return (
          <>
            <Button onClick={props.confirmFn}>IK BEN ZEKER</Button>
            <Button onClick={handleClose}>ANNULEREN</Button>
          </>
        );
        break;
      default:
        return <Button onClick={handleClose}>OK</Button>;
    }
  };

  const paperProps = () => {
    if (map) {
      return {
        sx: {
          display: "flex",
          height: "100%",
          width: "100%",
        },
      };
    } else {
      return { sx: {} };
    }
  };

  return (
    <Dialog
      PaperProps={paperProps()}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ height: "100%" }}
    >
      {title && (
        <DialogTitle
          sx={map && { textAlign: "center" }}
          id="alert-dialog-title"
        >
          {title}
        </DialogTitle>
      )}
      <DialogContent
        ref={mapdialog}
        sx={map && { padding: 0, flex: 1, alignItems: "center" }}
      >
        {text && (
          <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText>
        )}
        {map && map}
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        {renderButtons()}
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
