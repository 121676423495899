import React from "react";
import { Typography, Container, ListItem } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";

import BackButton from "../components/BackButton";
import { useNavigate } from "react-router-dom";
import "./LegalView.scss";

const LegalListItem = (props) => {
  return <ListItem sx={{ display: "list-item" }}>{props.children}</ListItem>;
};

const LegalLink = (props) => {
  return (
    <li>
      <a href={props.url} target="_blank" rel="noreferrer">
        <span className="legal-link-text">
          {props.text}
          <FontAwesomeIcon icon={faExternalLinkAlt} />
        </span>
        <FontAwesomeIcon icon={faArrowRight} />
      </a>
    </li>
  );
};

const LegalView = () => {
  const navigate = useNavigate();
  return (
    <div className="legal-page" style={{ padding: "1rem" }}>
      <BackButton
        onClick={() => {
             navigate("/");
          //navigate("/settings");
        }}
        style={{ position: "absolute", top: "0", left: "0" }}
      ></BackButton>
      <Container
        sx={{
          mt: "30px",
          padding: "0",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          boxSizing: "border-box",
        }}
      >
        <Typography variant="h5" sx={{ mb: 2, mt: 2 }} align="left">
          Wettelijk
        </Typography>
        <Typography variant="p" sx={{ mb: 2 }} align="left">
          Deze website is eigendom van Mentoring Systems BVBA, Valentijn
          Hennemanstraat 6, 8020 Oostkamp.
        </Typography>
        <Typography variant="h5" sx={{ mb: 2 }} align="left">
          Contact
        </Typography>
        <Container sx={{mb: 2, padding: "0" }}>
          <ListItem sx={{ display: "list-item", padding: "0" }}>
            Via telefoon: <a href="tel:050683038">050 68 30 38</a>
          </ListItem>
          <ListItem sx={{ display: "list-item", padding: "0" }}>
            Via mail: <a href="mailto:backoffice@mentoringsystems.be">backoffice@mentoringsystems.be</a>
          </ListItem>
        </Container>
        <Typography variant="p" sx={{ mb: 2 }} align="left">
          Voor CRI-TO zijn de algemene voorwaarden, het privacybeleid, de gebruiksvoorwaarden en de cookiepolicy van Mentoring Systems BVBA van toepassing. Deze kunnen ook nagelezen worden op de website van <a href="https://www.mentoringsystems.be" target="blank">
            Mentoring Systems BVBA
          </a>
        </Typography>
        <div className="legal-urls">
          <ul>
            <LegalLink
              url={
                "https://mentoringsystems.be/algemene-voorwaarden-general-conditions"
              }
              text={"Algemene Voorwaarden"}
            />
            <LegalLink
              url={
                "https://mentoringsystems.be/documents/Privacybeleid_OvereenkomstGegevensgebruik_MentoringSystems_NL-ENG.pdf"
              }
              text={"Privacybeleid"}
            />
            <LegalLink
              url={
                "https://mentoringsystems.be/documents/Gebruiksvoorwaarden_MentoringSystems_NL-ENG.pdf"
              }
              text={"Gebruiksvoorwaarden"}
            />
            <LegalLink
              url={
                "https://mentoringsystems.be/documents/Cookiebeleid_MentoringSystems_NL-ENG.pdf"
              }
              text={"Cookiepolicy"}
            />
          </ul>
        </div>
        <Typography variant="p" sx={{ mb: 2 }} align="left">
          Enkele voorwaarden vermelden we nog eens expliciet.
          <br />
          We definiëren hiervoor de ADMIN als de gebruiker van CRI-TO die de
          schattenjacht uitwerkt en publiceert voor de spelers. De SPELER als de
          persoon die de schattenjacht volgt en speelt.
        </Typography>
        <LegalListItem>
          De ADMIN let op dat de gebruikte foto’s, afbeeldingen en dergelijke
          vrij zijn van rechten. Voor elke inbreuk op de rechten is de ADMIN
          verantwoordelijk.{" "}
        </LegalListItem>
        <LegalListItem>
          De ADMIN let er op dat de gebruikte foto’s, afbeeldingen en dergelijke
          geen inbreuk zijn op de rechten van de privacy en verdere rechten
          zoals bepaald door de actuele GDPR-wetgeving.
        </LegalListItem>
        <LegalListItem>
          De ADMIN vergewist zich ervan, dat de aangegeven coördinaten voor de
          schattenjacht mogen gebruikt en vermeld worden.
        </LegalListItem>
        <LegalListItem>
          De SPELER die een e-mailadres achterlaat doet dit om deel te nemen aan
          de eventuele prijsloterij en stemt ermee in, dat het e-mailadres voor
          dit doel opgeslagen wordt. De SPELER stemt erin toe om desgevallend
          een bericht te ontvangen met het resultaat van de prijsloterij.
        </LegalListItem>
        <LegalListItem>
          De prijsloterij staat volledig los van het correct invullen van de
          vragen.
        </LegalListItem>
        <LegalListItem>
          De SPELER heeft geen recht op inzage in de deelnemers van de
          prijsloterij en kan de prijs/prijzen niet in specimen laten omzetten.
        </LegalListItem>
      </Container>
    </div>
  );
};

export default LegalView;
