import React, { useState } from "react";
import logo from "./critologo.svg";
import { Toggle } from "react-toggle-component";

import "./Cookies.scss";

const Cookies = (props) => {
  const [isValid, setIsValid] = useState(false);

  const onNextClick = () => {
    if (isValid) {
      window.localStorage.setItem("seenIntro", true);
      props.setSeenIntro(true);
    } else {
      isValidFn();
    }
  };

  const isValidFn = () => {
    return !isValid ? { display: "block" } : { display: "none" };
  };

  return (
    <div className="intro-wrapper">
      <div className="intro-container-wrapper">
        <div className="intro-container-logo">
          <img src={logo} className="crito-logo-intro-small" alt="Crito logo" />
        </div>
        <div className="intro-container-content">
          <p
            dangerouslySetInnerHTML={{
              __html:
                "Om deze app te gebruiken moet u minimaal <span class='fori-highlight-orange'>functionele cookies</span> toelaten.",
            }}
          ></p>
          <div className="toggle-container">
            <Toggle
              name="cookiestoggle"
              checked={isValid}
              onToggle={(e) => {
                setIsValid((prev) => !prev);
              }}
              leftBackgroundColor="tomato"
              rightBackgroundColor="#1976d2"
              leftBorderColor="tomato"
              rightBorderColor="#1976d2"
              leftKnobColor="white"
              rightKnobColor="white"
            />
            <label
              htmlFor="cookiestoggle"
              className="cookietoggle-label"
              dangerouslySetInnerHTML={{
                __html: "Ik zet de functionele<br />cookies aan.",
              }}
            ></label>
          </div>
          <p className="intro-warning" style={isValidFn()}>
            {"De cookies moeten aanvaard worden voor je verder kan!"}
          </p>
        </div>
      </div>
      <div className="intro-navigation-container">
        <div className="intro-navigation-next-button" onClick={onNextClick}>
          {"OK"}
        </div>
      </div>
    </div>
  );
};

export default Cookies;
