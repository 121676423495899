import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { UrlContextProvider } from "./urlContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
      secondary: "#E20077"
    }
  },
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        startIcon: {
          margin: 0
        }
      }
    }
  }
});

ReactDOM.render(
  <Router>
    <UrlContextProvider>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </UrlContextProvider>
  </Router>,
  document.getElementById("root")
);
