import React, {useState, useEffect} from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./ProgressIndicator.scss";

const ProgressIndicator = (props) => {
  const [completed, setCompleted] = useState(false);
  const { completeValue, value, completeText, completeIcon } = props;
  let text = value;

  if (value >= completeValue) {
    text = completeText;
  }

  useEffect(() => {
    if (value >= completeValue) {
      setCompleted(true);
    }
  },[value, completeValue])

  return (
    <div className="progress-indicator" style={props.style}>

    {(completed && completeIcon) ? completeIcon:<CircularProgressbar
      value={value}
      maxValue={completeValue}
      text={`${text}`}
      styles={buildStyles({
        textSize: "32px"
      })}
    />}

    </div>
  );
};

export default ProgressIndicator;
