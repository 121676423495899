import React, { useState } from "react";
import Modal from "../Modal";
import LeafletMap from "../leafletmap/LeafletMap";

const MapDialog = (props) => {
  const [zoom, setZoom] = useState(12);

  return (
    <Modal
      open={props.open}
      setOpen={props.setOpen}
      type={"map"}
      map={
        <LeafletMap
          zoom={zoom}
          setZoom={setZoom}
          polygon={props.polygon}
          currentPosition={props.currentPosition}
          markers={props.markers}
        />
      }
      title="Huidige Locatie"
    />
  );
};

export default MapDialog;
