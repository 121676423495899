import React, { useState, useEffect, useMemo } from "react";
import "./TabPanel.scss";
import { CSSTransition } from "react-transition-group";

// first component I made :D
// tabpanel taking full available size using flex
// this component mainly helps removing a ridiculous amount of configuration.
//UPGRADE WITH ANIMATIONS . .. :)

const AnimatedTab = (props) => {
  const { isActive, index, value, removeCurrentTab, animationTimeout } = props;
  const [animationState, setAnimationState] = useState(false);

  //Only animate the current tab
  useEffect(() => {
    setAnimationState(index === value);
  }, [value]);

  //Remove currenttab from screen ...
  useEffect(() => {
    if (removeCurrentTab && index === value) {
      setAnimationState(false);
    }
  }, [removeCurrentTab]);

  return (
    <CSSTransition in={animationState} timeout={animationTimeout}>
      <div className={`animated-tabbodydiv`} style={{transition: `transform ${animationTimeout}ms, opacity ${animationTimeout / 2 }ms`}}>{props.content()}</div>
    </CSSTransition>
  );
};

const TabPanel = (props) => {
  const {start} = props;
  const [animationTimeout, setAnimationTimeout] = useState(200); //Also edit the CSS!
  const [value, setValue] = useState(0);
  const [removeCurrentTab, setRemoveCurrentTab] = useState(false);

  const handleTabClick = (index) => {
    setValue(index);
  };

  useEffect(() => {
    setValue(start);
  },[])


  const nextTab = () => {
    setRemoveCurrentTab(true);
    setTimeout(() => {
      setRemoveCurrentTab(false);
      setValue((prevValue) => prevValue + 1);
    }, animationTimeout);
  };

  //HOF
  const WrapTab = (index, value, arr, content) => {
    return (
      <AnimatedTab
        animationTimeout={animationTimeout}
        content={content}
        removeCurrentTab={removeCurrentTab}
        value={value}
        index={index}
        key={index}
      />
    );
  };

  //check for render props ...

  if (typeof props.children === "function") {
    return (
      <div className="tabpanel" style={props.style ? props.style : {}}>
        <div className="tabbody">{props.children(WrapTab, value, nextTab)}</div>
      </div>
    );
  } else {
    return (
      <div className="tabpanel" style={props.style ? props.style : {}}>
        {(props.tabbar === true || typeof props.tabbar === "undefined") && (
          <div className="tabbar">
            {props.children.map((cmp, index) => (
              <button
                key={index}
                className={value === index ? "tabbtn selectedtab" : "tabbtn"}
                onClick={() => handleTabClick(index)}
              >
                {cmp.props.tabtitle}
              </button>
            ))}
          </div>
        )}
        <div className="tabbody">
          {props.children.map((cmp, index) => (
            <div
              className="tabbodydiv"
              key={index}
              style={
                index === value
                  ? {}
                  : {
                      display: "none",
                    }
              }
            >
              {cmp}
            </div>
          ))}
        </div>
      </div>
    );
  }
};

export default TabPanel;
