import L from 'leaflet';
import Circle from './circle.svg';
import CircleShadow from './1C6013FF.png';

const iconCircle = new L.Icon({
    iconUrl: Circle,
    iconRetinaUrl: Circle,
    shadowUrl: CircleShadow,
    shadowSize: new L.Point(19, 19),
    iconSize: new L.Point(20, 20),
    className: 'leaflet-circle-icon'
});

export { iconCircle };
